import { PagePrenotaVisitaStoryblok } from '@/types/types-storyblok';
import { StoryblokReactComponent } from './componentsMap';
import { StoryblokComponent } from './storyblokComponent';

export const StoryblokPageComponent: StoryblokReactComponent<PagePrenotaVisitaStoryblok> = ({ component, story, additionalData }) => {
  return (
    <>
      {component.body?.map((blok, index) => (
        <StoryblokComponent
          story={story}
          key={index}
          component={blok}
          additionalData={{ ...additionalData, serviceName: component.serviceName }}
        />
      ))}
    </>
  );
};
