import { Lora } from 'next/font/google';
import { StoryblokReactComponent } from './componentsMap';
import { SmallCardsSectionStoryblok } from '@/types/types-storyblok';
import Image from 'next/image';

import { MARK_BOLD, render } from 'storyblok-rich-text-react-renderer';
import clsx from 'clsx/lite';
import { StoryblokComponent } from './storyblokComponent';
import Link from 'next/link';
import { resolveWebLink } from '@/storyblok/utils/link';
import { capitalizeWordsInString } from '@/utils/string';
import { StoryblokServiceCard } from '@/app/prenota-visita/_storyblok/smallCardsSection/serviceCard';

const lora = Lora({ subsets: ['latin'], weight: '500', style: 'italic' });
const topCities = ['roma', 'milano', 'bologna', 'brescia', 'bergamo', 'monza', 'padova', 'firenze', 'verona'];

const generateComponentCards = (serviceSlug: string, serviceName: string, location: string) => ({
  _uid: `${serviceSlug}-${location}-card`,
  link: {
    id: '',
    url: `/prenota-visita/${serviceSlug}/${location}`,
    linktype: 'url',
    fieldtype: 'multilink',
    cached_url: `/prenota-visita/${serviceSlug}/${location}`,
  },
  service: serviceName,
  location: capitalizeWordsInString(location),
  component: 'serviceCard',
});

export const StoryblokSmallCardsSection: StoryblokReactComponent<SmallCardsSectionStoryblok> = ({ component, story, additionalData }) => {
  const urlLink = component?.urlLink?.[0];
  const hasCustomList = component.cards?.length > 0;
  const isPrenotaVisitaService = additionalData.pageKey === 'pv-service';

  const prenotaVisitaDefaultCards = topCities.map(city =>
    generateComponentCards(additionalData.service ?? '', additionalData.serviceName ?? '', city),
  );
  return (
    <section className={clsx(component.background === 'lightGray' ? 'bg-[#F3F8F8]' : 'bg-white', 'shadow-1 px-4 py-14 md:px-20 lg:py-20')}>
      <h2 className="flex flex-col items-center gap-4 text-center text-3xl">
        {component.titleIcon?.filename && (
          <Image
            className="hidden lg:inline-block"
            src={component.titleIcon.filename}
            alt={component.titleIcon.alt ?? ''}
            aria-hidden="true"
            height={28}
            width={28}
          />
        )}
        {render(component.title, {
          markResolvers: {
            [MARK_BOLD]: child => <span className={clsx(lora.className, 'text-elty-green')}>{child}</span>,
          },
        })}
      </h2>
      {component.titleSeparator && <hr className="mb-14 mt-5 border-elty-green md:mx-auto md:w-1/2 lg:border-none" />}
      <ul
        className={clsx(
          'mx-auto flex max-w-screen-xl flex-col gap-4 md:gap-8',
          !component.titleSeparator && 'mt-20',
          component.desktopCardsDisposition === 'row' && 'lg:flex lg:flex-row',
          component.desktopCardsDisposition === 'grid' && 'md:grid md:grid-cols-3',
        )}
      >
        {hasCustomList
          ? component.cards.map(card => (
              <StoryblokComponent story={story} key={card._uid} component={card as any} additionalData={additionalData} />
            ))
          : isPrenotaVisitaService
            ? prenotaVisitaDefaultCards.map(card => (
                <StoryblokServiceCard story={story} key={card._uid} component={card as any} additionalData={additionalData} />
              ))
            : null}
      </ul>
      {urlLink?.link && urlLink?.title && (
        <div className="my-16 flex justify-center font-semibold text-primary underline">
          <Link href={resolveWebLink(urlLink.link)}>{urlLink.title}</Link>
        </div>
      )}
    </section>
  );
};
