import { StoryblokComponent } from '@/types/types-storyblok';
import { FunctionComponent } from 'react';
import { StoryblokPageComponent } from './_page';
import { StoryblokSmallCardsSection } from './smallCardsSection';
import { StoryblokServiceCard } from './smallCardsSection/serviceCard';
import { StoryblokSmallCardWithImage } from './smallCardsSection/smallCard';

import { type StoryblokStory } from 'storyblok-generate-ts';
import { VisitMainInformation } from '@/app/prenota-visita/_storyblok/visitMainInformation';
import { VisitMainInformationCard } from '@/app/prenota-visita/_storyblok/visitMainInformationCard';
import { StoryblokHomeHeroBanner } from '@/app/prenota-visita/_storyblok/homeHeroBanner';
import Footer from '@/components/layouts/defaultLayout/footer';
import { StoryblokHomeHeroBannerSearchForm } from '@/app/prenota-visita/_storyblok/searchForm';
import { StoryblokHowWorkText } from '@/app/prenota-visita/_storyblok/howWorkText';
import { StoryblokMedicalVisitList } from '@/app/prenota-visita/_storyblok/medicalVisitList';
import { StoryblokRelatedArticles } from '@/app/prenota-visita/_storyblok/relatedArticles';
import { StoryblokHowWorkCards } from '@/app/prenota-visita/_storyblok/howWorkCards';
import { StoryblokHowWorkCardsCard } from '@/app/prenota-visita/_storyblok/howWorkCardsCard';
import { StoryblokMedicalVisitCard } from '@/app/prenota-visita/_storyblok/medicalVisitCard';
import { StoryblokBookingCardListing } from '@/app/prenota-visita/_storyblok/bookingCardListing';

export type StoryblokReactComponent<T extends StoryblokComponent> = FunctionComponent<StoryblokReactComponentProps<T>>;
type storyblokPages = 'pv-home' | 'pv-service' | 'pv-service-location';
export type StoryblokReactComponentProps<T extends StoryblokComponent> = {
  component: T;
  story: StoryblokStory<StoryblokComponent>;
  additionalData: {
    pageKey: storyblokPages;
    service?: string;
    serviceName?: string;
    location?: string;
    searchParams?: { page: string; exclude: string; filterService: string; location: string };
  };
};

export const componentsMap: {
  [SBComponent in StoryblokComponent as SBComponent['component']]: StoryblokReactComponent<SBComponent>;
} = {
  page: StoryblokPageComponent,
  pagePrenotaVisita: StoryblokPageComponent,
  smallCardsSection: StoryblokSmallCardsSection,
  serviceCard: StoryblokServiceCard,
  homeHeroBanner: StoryblokHomeHeroBanner,
  smallCardWithImage: StoryblokSmallCardWithImage,
  visitMainInformation: VisitMainInformation,
  visitMainInformationCard: VisitMainInformationCard,
  Footer: Footer,
  howWorkText: StoryblokHowWorkText,
  medicalVisitList: StoryblokMedicalVisitList,
  medicalVisitCard: StoryblokMedicalVisitCard,
  post: () => null,
  homeHeroBannerSearchForm: StoryblokHomeHeroBannerSearchForm,
  relatedArticles: StoryblokRelatedArticles,
  howWorkCards: StoryblokHowWorkCards,
  howWorkCardsCard: StoryblokHowWorkCardsCard,
  bookingCardListing: StoryblokBookingCardListing,
  category: () => null,
};
