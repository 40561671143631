import { SearchLocation } from '@/utils/search';

const cityGeolocationList: SearchLocation[] = [
  { name: 'bari', latitude: 41.125784, longitude: 16.862029 },
  { name: 'bergamo', latitude: 45.694495, longitude: 9.669873 },
  { name: 'bologna', latitude: 44.49382, longitude: 11.342633 },
  { name: 'brescia', latitude: 45.539802, longitude: 10.220021 },
  { name: 'capri', latitude: 40.550882, longitude: 14.244318 },
  { name: 'catania', latitude: 37.502361, longitude: 15.087372 },
  { name: 'cinisello-balsamo', latitude: 45.556059, longitude: 9.214317 },
  { name: 'firenze', latitude: 43.769871, longitude: 11.255576 },
  { name: 'genova', latitude: 44.40726, longitude: 8.933862 },
  { name: 'lecco', latitude: 45.855376, longitude: 9.389605 },
  { name: 'messina', latitude: 38.193757, longitude: 15.554208 },
  { name: 'milano', latitude: 45.4639447, longitude: 9.1885578 },
  { name: 'modena', latitude: 44.645889, longitude: 10.925571 },
  { name: 'monza', latitude: 45.583442, longitude: 9.273526 },
  { name: 'napoli', latitude: 40.835934, longitude: 14.248783 },
  { name: 'padova', latitude: 45.407717, longitude: 11.873445 },
  { name: 'palermo', latitude: 38.111227, longitude: 13.352443 },
  { name: 'pavia', latitude: 45.186004, longitude: 9.154637 },
  { name: 'pesaro', latitude: 43.909811, longitude: 12.913123 },
  { name: 'pisa', latitude: 43.7153068, longitude: 10.4015047 },
  { name: 'prato', latitude: 43.880598, longitude: 11.096926 },
  { name: 'roma', latitude: 41.89332, longitude: 12.482932 },
  { name: 'torino', latitude: 45.067755, longitude: 7.682489 },
  { name: 'trieste', latitude: 45.649649, longitude: 13.777278 },
  { name: 'varese', latitude: 45.817549, longitude: 8.826353 },
  { name: 'verona', latitude: 45.438496, longitude: 10.992412 },
  { name: 'venezia', latitude: 45.475799, longitude: 12.271638 },
];

export const getCityLocation = (cityName?: string): SearchLocation | undefined => {
  const cityLocation = cityGeolocationList.find(ele => ele.name === cityName);
  if (!cityLocation) {
    // TODO Call Mapbox API to get geo by location name
    console.error(`getCityLocation: City Not Found ===> ${cityName}`);
    return;
  }
  console.log(`getCityLocation: OK ===> ${cityName}`);
  return cityLocation;
};
