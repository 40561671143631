import { StoryblokReactComponent } from './componentsMap';
import { HowWorkCardsCardStoryblok } from '@/types/types-storyblok';
import Image from 'next/image';
import { convertSlugToString } from '@scripts/staticBuildUtils';
import { capitalizeFirstCharInString } from '@/utils/string';
import { render, MARK_BOLD, MARK_LINK } from 'storyblok-rich-text-react-renderer';
import { replaceAdditionalInfoInRichText } from '@/app/prenota-visita/_storyblok/utils';

export const StoryblokHowWorkCardsCard: StoryblokReactComponent<HowWorkCardsCardStoryblok> = ({ component, additionalData }) => {
  return (
    <div className="flex flex-col gap-5 rounded-[20px] bg-white p-7 shadow-card md:p-8 lg:flex-row">
      <Image
        className="h-auto min-w-[215px] max-w-full self-center object-contain pr-5"
        src={component.image.filename}
        aria-hidden="true"
        height={215}
        width={219}
        alt={component.image.alt ?? ''}
      />
      <div className="flex h-full flex-col justify-center gap-4">
        <h2 className="text-3xl">{component.title}</h2>
        <p className="text-lg text-neutral-darker">
          {component.subtitle
            .replace('[[service]]', convertSlugToString(additionalData.service ?? ''))
            .replace('[[city]]', capitalizeFirstCharInString(additionalData.location ?? 'Milano'))}
        </p>
        <div className="text-[#6C7E8D]">
          {render(component.description, {
            markResolvers: {
              [MARK_BOLD]: child => <span className={'font-semibold'}>{child}</span>,
              [MARK_LINK]: (child, attr) => {
                const { href } = attr;

                if (child && href)
                  return (
                    <a href={`${href}`} data-cy="phone-number-cta" className={` text-center text-base font-normal text-primary md:text-sm`}>
                      <span className={`$ text-base font-semibold text-inherit underline md:text-sm`}>{child}</span>
                    </a>
                  );
                return null;
              },
            },

            textResolver: text =>
              replaceAdditionalInfoInRichText(text, { location: additionalData.location, service: additionalData.service }),
          })}
        </div>
      </div>
    </div>
  );
};
