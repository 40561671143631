import { Lora } from 'next/font/google';
import { StoryblokReactComponent } from './componentsMap';
import { BookingCardListingStoryblok } from '@/types/types-storyblok';

import { MARK_BOLD, render } from 'storyblok-rich-text-react-renderer';
import clsx from 'clsx/lite';
import Listing from '@/components/widgets/listing';
import { convertSlugToString } from '@scripts/staticBuildUtils';
import { getCityLocation } from '@scripts/geoLocation';
import { getDeliveryModeFilterFromQueryParameters, parseSearchPageNumberFromQueryString } from '@/utils/search';
import { capitalizeWordsInString, getStringFromQueryParam } from '@/utils/string';
import { getAlgoliaSearchResponse } from '@/api/algolia';
import { jsonLdMedicalClinicCarouselList } from '@/jsonLd/jsonLdCarousel';

const lora = Lora({ subsets: ['latin'], weight: '500', style: 'italic' });

export const StoryblokBookingCardListing: StoryblokReactComponent<BookingCardListingStoryblok> = async ({ component, additionalData }) => {
  const healthcareService = convertSlugToString(additionalData.service);
  const searchLocation = getCityLocation(additionalData.location);

  const page = parseSearchPageNumberFromQueryString(additionalData.searchParams?.page);
  const deliveryFilter = getDeliveryModeFilterFromQueryParameters(additionalData.searchParams?.exclude);
  const filters = {
    service: getStringFromQueryParam(additionalData.searchParams?.filterService),
    excludeDomicilary: deliveryFilter?.excludeDomicilary,
    excludeOnline: deliveryFilter?.excludeOnline,
    excludeOnsite: deliveryFilter?.excludeOnsite,
  };
  const searchResponse = await getAlgoliaSearchResponse({ text: healthcareService, searchLocation, page, filterObj: filters });
  const serviceText = capitalizeWordsInString(healthcareService);

  return (
    <section className={'shadow-1 justify-center space-y-7 bg-white px-4 py-7 lg:py-10'}>
      <script
        dangerouslySetInnerHTML={{ __html: JSON.stringify(jsonLdMedicalClinicCarouselList(searchResponse?.hits ?? [])) }}
        type="application/ld+json"
      />
      <h2 className="text-center text-3xl">
        {render(component.title, {
          markResolvers: {
            [MARK_BOLD]: child => <span className={clsx(lora.className, 'text-elty-green')}>{child}</span>,
          },
        })}
      </h2>
      <Listing
        service={serviceText}
        locationInfo={searchLocation as undefined}
        searchResultsServerSide={searchResponse}
        searchFilters={undefined}
        paginationPadding="md"
        filterLight
        showSearchInfo={false}
      />
    </section>
  );
};
