import { capitalizeWordsInString } from '@/utils/string';
import { convertSlugToString } from '@scripts/staticBuildUtils';

export const replaceAdditionalInfoInRichText = (text: string, additionalInfo: { service?: string; location?: string }) => {
  let newText = text;
  if (additionalInfo.service) {
    newText = newText.replaceAll('[[service]]', convertSlugToString(additionalInfo.service));
  }
  if (additionalInfo.location) {
    newText = newText.replaceAll('[[city]]', capitalizeWordsInString(additionalInfo.location));
  }
  return newText;
};
