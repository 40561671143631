import { CLIENT_ENV, SERVER_ENV } from '@/config/configuration';
import { WebClient } from '@slack/web-api';

export const slack = new WebClient(SERVER_ENV().SLACK_BUILD_MESSAGES_TOKEN ?? '');

let errorsThreadTSTask: Promise<string> | undefined;

export const sendErrorToSlack = async (message: string) => {
  try {
    if (CLIENT_ENV().NEXT_PUBLIC_ENV === 'development') {
      return;
    }
    const channel = SERVER_ENV().SLACK_BUILD_MESSAGES_CHANNEL;
    if (!channel) {
      throw new Error('SLACK_BUILD_MESSAGES_CHANNEL is not defined');
    }
    console.log('**channel', channel);

    const date = Date.now();
    // eslint-disable-next-line no-process-env
    const lastCommit = process?.env?.LAST_COMMIT_ID ?? 'none';
    let buildId = `${lastCommit}_${date}`;
    if (!buildId) {
      console.warn('BUILD_ID is not defined, using current time as build id');
      buildId = `${new Date().getFullYear()}-${new Date().getMonth()}-${new Date().getDate()}-${new Date().getHours()}-${Math.floor(
        new Date().getMinutes() / 10,
      )}`;
    }

    if (!errorsThreadTSTask) {
      const tsTask = new Promise<string>(async resolve => {
        let ts = await slack.conversations.history({ channel, limit: 1 }).then(m => m.messages?.find(m => m.text?.includes(buildId))?.ts);
        if (!ts) {
          ts = await slack.chat
            .postMessage({
              channel,
              text: `[${CLIENT_ENV().NEXT_PUBLIC_ENV}] There has been some errors with Booking Build ${buildId}`,
            })
            .then(m => m.ts);
        }
        if (!ts) {
          throw new Error('Could not create a thread for the build');
        }
        resolve(ts);
      });
      errorsThreadTSTask = tsTask;
    }
    const ts = await errorsThreadTSTask;

    await slack.chat.postMessage({
      channel,
      text: message,
      thread_ts: ts,
      blocks: [
        {
          type: 'section',
          text: {
            type: 'mrkdwn',
            text: message,
          },
        },
      ],
    });
  } catch (error) {
    console.error('Error sending slack message');
  }
};
